import React, { useEffect, useState } from "react";
import { ReactComponent as Live } from '../live.svg';

const MatchaCounter = () => {
  const [count, setCount] = useState(0); // Target number from API
  const [displayCount, setDisplayCount] = useState(0); // Animated display number
  const [zoom, setZoom] = useState(1);

  const fetchCount = async () => {
    try {
      const response = await fetch('https://gottea-search-5gtrn.ondigitalocean.app/sales/matchacounts', {
        method: 'POST',
        headers: {'content-type': 'application/json'},
        mode: 'cors', // Enable CORS,
        body: JSON.stringify({}) // Sending empty body as per original curl
      });

      if (!response.ok) {
        throw new Error("Failed to fetch count");
      }

      const data = await response.json();
      if (data && data.data) {
        setCount(data.data.totalMatchaDrinks); // Adjust if the key is different
      }
    } catch (error) {
      console.error("Error fetching count:", error);
    }
  };

  // Initial fetch + polling every 30 seconds
  useEffect(() => {
    fetchCount();
    const interval = setInterval(fetchCount, 8000); // 30 seconds
    return () => clearInterval(interval);
  }, []);

  // Animate the number change smoothly
  useEffect(() => {
    const duration = 1500;
    const intervalTime = 50;
    const steps = duration / intervalTime;
    const increment = (count - displayCount) / steps;

    let currentCount = displayCount;
    const interval = setInterval(() => {
      currentCount += increment;
      if ((increment > 0 && currentCount >= count) || (increment < 0 && currentCount <= count)) {
        currentCount = count;
        clearInterval(interval);
      }
      setDisplayCount(Math.round(currentCount));
    }, intervalTime);

    return () => clearInterval(interval);
  }, [count]);

  // Responsive zoom
  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width < 520) setZoom(0.45);
      else if (width < 670) setZoom(0.65);
      else if (width < 768) setZoom(0.8);
      else if (width < 950) setZoom(0.7);
      else if (width < 1200) setZoom(0.8);
      else setZoom(1);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const formattedCount = displayCount.toLocaleString("en-IN").split("");

  return (
    <div style={{ fontFamily: 'Lexend Deca' }}>
      <div className="w-full min-h-screen bg-[#1B994F] flex items-center justify-center px-4 py-10 font-['Lexend Deca'] font-medium">
        <div style={{ position: 'relative' }} className="w-full max-w-[1400px] p-6 md:p-10 bg-[#FFFDEE] rounded-[24px] flex flex-col justify-center items-center">
          <div style={{ zoom }}>
            <Live width={640} />
          </div>
          <div className="w-full bg-[#DBEDD5] rounded-[24px] flex flex-col items-center justify-center mt-6 px-4 py-6">
            <div style={{ zoom }} className="flex flex-wrap justify-center items-center gap-2 sm:gap-3 mt-2">
              {formattedCount.map((char, idx) => (
                <div key={idx}>
                  {char === "," ? (
                    <div style={{ fontSize: '6rem', margin: '0.5rem' }} className="font-extrabold text-[#404040] w-4 sm:w-6 text-center">
                      {char}
                    </div>
                  ) : (
                    <div className="w-[65px] sm:w-[90px] md:w-[135px] h-[100px] sm:h-[140px] md:h-[185px] bg-[#404040] rounded-[12px] sm:rounded-[16px] md:rounded-[20px] flex items-center justify-center">
                      <span className="text-white text-4xl sm:text-6xl md:text-[92.57px] font-extrabold">
                        {char}
                      </span>
                    </div>
                  )}
                </div>
              ))}
            </div>

            <div className="mt-6 text-center">
              <p className="text-[#167E41] text-2xl sm:text-3xl md:text-[40px] font-semibold font-['Lexend Deca'] tracking-tight">
                Serving You Matcha Since November 2020
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MatchaCounter;