import React, { useState } from 'react';

const WebViewInput = ({ imageUrl, setImageUrl }) => {
  const [url, setUrl] = useState(imageUrl || '');
  const [error, setError] = useState('');

  const handleChange = (e) => {
    const inputUrl = e.target.value;
    setUrl(inputUrl);

    // Regular expression to validate URL
    const urlPattern = new RegExp(
      '^(https?:\\/\\/)?' + // validate protocol
      '((([a-zA-Z0-9$-_@.&+!*\\(\\),]+\\.)+[a-zA-Z]{2,})|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR IP (v4) address
      '(\\:\\d+)?(\\/[-a-zA-Z0-9$%_.~+!*\\(\\),]*)*' + // port and path
      '(\\?[;&a-zA-Z0-9$%_.~+=-]*)?' + // query string
      '(\\#[-a-zA-Z0-9$%_.~+=]*)?$', // fragment locator
      'i'
    );

    if (urlPattern.test(inputUrl)) {
      setError('');
      setImageUrl(inputUrl);
    } else {
      setError('Please enter a valid URL.');
    }
  };

  return (
    <div className="mb-4">
      <label htmlFor="webview-url" className="block mb-1">
        Webview URL
      </label>
      <input
        id="webview-url"
        type="url"
        className="w-full p-2 border rounded"
        placeholder="Enter a valid URL"
        value={url}
        onChange={handleChange}
        required
      />
      {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
    </div>
  );
};

export default WebViewInput;